import "./Footer.css";
import { ReactComponent as Icon_Facebook } from "../assets/icon/Icon_Facebook.svg";
import { ReactComponent as Icon_Instagram } from "../assets/icon/Icon_Instagram.svg";
import { ReactComponent as Icon_LinkedIn } from "../assets/icon/Icon_LinkedIn.svg";
import { ReactComponent as Icon_Logo } from "../assets/svg/BeyneLogoBlueCircle.svg";
import { ReactComponent as Icon_Logo_text } from "../assets/svg/BeyneLogoWhiteType.svg";
import { ReactComponent as Icon_Mail } from "../assets/icon/Icon_Mail.svg";
import { ReactComponent as Icon_Phone } from "../assets/icon/Icon_Phone.svg";

import { useTranslation } from "react-i18next";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-scroll";
let footer__btn = document.querySelector(".footer__btn");

function Footer() {
  const { t } = useTranslation();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    let footer__checkbox = document.querySelector("#footer__checkbox");
    if (footer__checkbox.checked) {
      emailjs
        .sendForm(
          "service_y2w7ht1",
          "template_ozd5x8j",
          form.current,
          "yMIP9xgIB00n8m9_h"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      footer__checkbox.classList.remove("wrong");
      e.target.reset();
    } else {
      footer__checkbox.classList.add("wrong");
    }
  };
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__item footer__branding">
            <div className="__logo-footer niz-25">
              <Icon_Logo className="logo logo-1" />
              <Icon_Logo_text className="logo logo-2" />
            </div>
            <div className="footer__subtitle niz-25">
              {t("footer__subtitle")}
            </div>
            <div className="footer__social non_social">
              <a target="_blank" href="https://www.facebook.com/beyneaikz/">
                <Icon_Facebook className="social__link" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/beyne-ai/?trk=public_profile_topcard-current-company&originalSubdomain=kz"
              >
                <Icon_LinkedIn className="social__link" />
              </a>
              <a
                target="_blank"
                href="https://instagram.com/beyneai?igshid=YmMyMTA2M2Y="
              >
                <Icon_Instagram className="social__link" />
              </a>
            </div>
          </div>
          <div className="footer__item footer__menu">
            <div className="footer__company">
              <div className="footer__minititle niz-25">
                {t("footer__minititle1")}
              </div>
              <Link
                to="products"
                duration={500}
                className="footer__menu-item niz-10"
              >
                {t("footer__menu_item1")}
              </Link>
              <Link
                to="solutions"
                duration={500}
                className="footer__menu-item niz-10"
              >
                {t("footer__menu_item2")}
              </Link>
              <Link to="aboutus" duration={500} className="footer__menu-item video__none">
                {t("footer__menu_item3")}
              </Link>
            </div>
          </div>
          <div className="footer__item footer__contact">
            <div className="footer__minititle niz-25">
              {t("footer__minititle2")}
            </div>
            <a
              href="mailto:info@beyneai.kz"
              className="footer__menu-item contact__email niz-10"
            >
              <Icon_Mail className="footer__menu-img" alt="" />
              info@beyneai.kz
            </a>
            <a
              href="tel:+77024050212"
              className="footer__menu-item contact__tel"
            >
              <Icon_Phone className="footer__menu-img" />+7 702 405 02 12
            </a>
          </div>
          <div className="footer__item footer__social active__social">
            <a target="_blank" href="https://www.facebook.com/beyneaikz/">
              <Icon_Facebook className="social__link" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/beyne-ai/?trk=public_profile_topcard-current-company&originalSubdomain=kz"
            >
              <Icon_LinkedIn className="social__link" />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/beyneai?igshid=YmMyMTA2M2Y="
            >
              <Icon_Instagram className="social__link" />
            </a>
          </div>
        </div>
        <div className="footer__border">Lorem</div>
        <div className="end">
          <div className="end__text">Beyne.ai © 2022. All rights reserved.</div>
          {/* <div className="end__links">
            <a
              target="_blank"
              href="https://beyne.ai/privacy-policy"
              className="end__link"
            >
              Privacy Policy
            </a>
            <a target="_blank" href="" className="end__link mar_l_25 disabled">
              Cookies
            </a>
            <a
              target="_blank"
              href="https://beyne.ai/oferta"
              className="end__link mar_l_25"
            >
              Legal Advice
            </a>
            <a target="_blank" href="" className="end__link mar_l_25 disabled">
              FAQ
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
